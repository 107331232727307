@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Work Sans';
  src: local('Work Sans'),
    url(../assets/fonts/WorkSans-Medium.ttf) format('truetype');
}

*,
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-rendering: optimizeSpeed;
  text-shadow: 1px 3px 1px rgba(0, 0, 0, 0.01);
  font-family: 'Poppins', 'Noto Sans JP', sans-serif;
}

html {
  min-height: -webkit-fill-available;
}

body {
  overflow-y: overlay;
  overflow-x: hidden;
  min-height: -webkit-fill-available;

  &::-webkit-scrollbar {
    width: 0;
  }
}

p {
  margin: 0;
}

.font__Work__Sans {
  font-family: 'Work Sans', sans-serif !important;
}

.active-service {
  background: linear-gradient(
    90deg,
    rgb(255, 84, 0) 0%,
    rgb(255, 138, 0) 100%
  ) !important;
  color: rgb(255, 255, 255) !important;
}

.ant-input:focus,
.ant-input-focused {
  border-color: rgb(255, 84, 0);
}

.ant-input:hover {
  border-color: rgb(255, 84, 0);
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: rgb(255, 84, 0);
}

a:hover {
  color: rgb(255, 84, 0) !important;
}

.ant-image {
  width: 100%;
}

.splide__track {
  padding-right: 5px !important;
}

.dark {
  background-color: #333;
  color: #fff;
}
.light {
  background-color: #fff;
  color: #333;
}

.splide__arrow {
  background-color: #ffffff;
  color: #333333;
  border: 2px solid #333333;
  border-radius: 50%;
  padding: 6px;
  margin: 0 -50px;
}

.splide__arrow:disabled:hover {
  border: 2px solid #333333;
  svg {
    fill: #333333;
  }
}

.splide__arrow:hover {
  border: 2px solid #ff5300;

  svg {
    fill: #ff5300;
  }
}

.about-info-type-1 {
  display: inline;
  font-size: 16px;
  color: #fff;
  position: relative;
}

.about-info-type-2 {
  display: inline;
  font-size: 16px;
  line-height: 24px;
  color: #ff5300;
  position: relative;
}

.about-info-type-1::before {
  content: '';
  width: 40px;
  height: 1px;
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
}
.about-info-type-1::after {
  content: '';
  width: 40px;
  height: 1px;
  position: absolute;
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
}

.about-info-type-2::before {
  content: '';
  width: 40px;
  height: 1px;
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ff5300;
}
.about-info-type-2::after {
  content: '';
  width: 40px;
  height: 1px;
  position: absolute;
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ff5300;
}

.cp {
  cursor: pointer;
}
.flex-flow {
  flex-flow: wrap-reverse;
}

.box-contact {
  padding: 20px;
}
.mapFrame {
  width: 100%;
  height: 450px;
  margin-top: 25px;
  padding-bottom: 100px;
}

//Ipad

@media screen and (min-width: 768px) {
  .flex-flow {
    flex-flow: row wrap;
  }
  .box-contact {
    padding: 74px 0 150px;
  }

  .mapFrame {
    width: 600px;
    height: 450px;
    margin: 0;
    padding: 0;
  }
}

.ant-rate {
  color: #ff5300;
}
